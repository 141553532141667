import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const SkillProgress = (props) => {
  const [state, setState] = useState(0);

  useEffect(() => {
    if (state < props.value) {
      setTimeout(() => {
        setState((prev) => prev + 1);
      }, 20);
    }
  }, [state]);

  const progressStyle = {
    width: state + "%",
  };

  return (
    <div className="skills-technology-container">
      <div className="skills-technology-single">
        <div className="skills-progress-info-container">
          <span className="skills-progress-info-title">{props.title}</span>
          <span className="skills-progress-info-value">{state}%</span>
        </div>
        <div className="skills-progress-container">
          <div className="skills-progress-value" style={progressStyle}></div>
        </div>
      </div>
    </div>
  );
};

export default SkillProgress;
