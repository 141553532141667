import { React, useState } from "react";

const Article = (props) => {
  const [isHover, setIsHover] = useState(false);
  const spanstyle = {
    color: isHover ? "black" : "white",
  };
  const istyle = {
    color: isHover ? "black" : "#0099c4",
  };

  return (
    <article
      onMouseOver={() => {
        setIsHover(true);
      }}
      onMouseOut={() => {
        setIsHover(false);
      }}
    >
      <i className={`${props.i}`} style={istyle}></i>
      <h3>{props.h3}</h3>
      <span style={spanstyle}>{props.span}</span>
    </article>
  );
};

export default Article;
