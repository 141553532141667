import { React, useState } from "react";

const Service_post = (props) => {
  const [state, setState] = useState(false);
  function handleState() {
    setState((prev) => !prev);
  }

  const text_style = {
    color: state ? "black" : "white",
  };

  const container_style = {
    backgroundColor: state ? "white" : "#20232A",
    border: "1px solid black",
  };

  const hr_style = {
    backgroundColor: state ? "black" : "white",
  };

  const back_style = {
    transform: state ? "rotate(0)" : "rotate(5deg)",
  };
  const text = props.data.text;
  return (
    <div className="services-post-container">
      <div className="services-post-back" style={back_style}></div>
      <div
        className="services-post"
        onMouseOver={handleState}
        onMouseOut={handleState}
        style={container_style}
      >
        <h3>{props.data.title}</h3>
        <hr style={hr_style} />
        {text.map((tex) => {
          return (
            <div className="services-post-list">
              <i className="fa fa-check"></i>
              <div className="service-post-text" style={text_style}>
                {tex}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Service_post;
