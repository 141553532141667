import React from "react";

const BarreProgress = () => {
  return (
    <div
      className="barre-progress"
      style={{
        width:
          (window.scrollY / (document.body.scrollHeight - 621)) * 100 + "vw",
      }}
    ></div>
  );
};

export default BarreProgress;
