import React from "react";
import Portfolio_post from "./components/Portfolio_post";

const Portfolio = () => {
  const data = [
    {
      title: "L'OR PRIMMER",
      imageUrl: "./images/lor.png",
      description:
        "Online sales site for a multitude of products from the company L'OR PRIMMER based in France",
      demoUrl: "https://lorprimmer.com",
      githubUrl: "https://github.com",
    },
    {
      title: "st market",
      imageUrl: "./images/st.png",
      description:
        "Online sales site for a multitude of technological products (computer, telephones, etc.) with an administrator platform",
      demoUrl: "http://stcode.epizy.com/STMarket",
      githubUrl: "https://github.com",
    },
    {
      title: "CHAT APP",
      imageUrl: "./images/chat.jpg",
      description: "Telegram themed messaging app where you can talk easily",
      demoUrl: "http://stcode.epizy.com/WhatsUpPolytech",
      githubUrl: "https://github.com",
    },
    {
      title: "CAMENCORP",
      imageUrl: "./images/camen.png",
      description:
        "Showcase website describing the services and exposing all the information of the Camencorp company",
      demoUrl: "https://camencorp.com",
      githubUrl: "https://github.com",
    },
  ];

  return (
    <section id="portfolio">
      <h1>Portfolio </h1>
      <span>My recent work</span>
      <div className="portfolio-container">
        {data.map((item) => {
          return <Portfolio_post data={item} />;
        })}
      </div>
    </section>
  );
};

export default Portfolio;
