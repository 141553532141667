import React from "react";

const Lien = () => {
  return (
    <div className="lien-container">
      <a
        href="https://facebook.com"
        target="_blank"
        rel="noreferrer"
        title="Facebook"
      >
        <i className="fa-brands fa-facebook-f social-link"></i>
      </a>
      <a
        href="https://whatsapp.com"
        target="_blank"
        rel="noreferrer"
        title="Whatsapp"
      >
        <i className="fa-brands fa-whatsapp social-link"></i>
      </a>
      <a
        href="https://linkedin.com"
        target="_blank"
        rel="noreferrer"
        title="linkedin"
      >
        <i className="fa-brands fa-linkedin-in social-link"></i>
      </a>
      <a
        href="mailto:thierrysitio@gmail.com"
        target="_blank"
        rel="noreferrer"
        title="Gmail"
      >
        <i className="fa fa-envelope social-link"></i>
      </a>
      <a
        href="https://github.com/STcodes"
        target="_blank"
        rel="noreferrer"
        title="Github"
      >
        <i className="fa-brands fa-github social-link"></i>
      </a>
    </div>
  );
};

export default Lien;
