import React from "react";

const Navbar = (props) => {
  const title = "< st.code />";

  function isActive(a, b) {
    if (a <= props.scrollY && props.scrollY <= b) {
      return {
        color: "#62DAFC",
      };
    }
  }

  return (
    <nav>
      <img src="./images/logo.png" alt="logo" className="logo" />
      &nbsp;&nbsp;&nbsp;&nbsp;
      <h3 className="title-logo">{title}</h3>
      <ul>
        <li>
          <a href="#home" style={isActive(0, 332)}>
            Home
          </a>
        </li>
        <li>
          <a href="#about" style={isActive(333, 1180)}>
            About me
          </a>
        </li>
        <li>
          <a href="#skills" style={isActive(1181, 2000)}>
            Skills
          </a>
        </li>
        <li>
          <a href="#services" style={isActive(2000, 3226)}>
            Services
          </a>
        </li>
        <li>
          <a href="#portfolio" style={isActive(3227, 4287)}>
            Portfolio
          </a>
        </li>
        <li>
          <a href="#qualification" style={isActive(4288, 5353)}>
            Qualifications
          </a>
        </li>
        <li>
          <a href="#testimonial" style={isActive(5354, 5820)}>
            Testimonials
          </a>
        </li>
        <li>
          <a href="#contact" style={isActive(5821, 6350)}>
            Contact
          </a>
        </li>
        {/* {props.scrollY} */}
        {/* {document.body.scrollHeight} */}
      </ul>
    </nav>
  );
};

export default Navbar;
