import { React, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Star from "./components/Star";
import { EffectCoverflow } from "swiper";
import "swiper/css/effect-coverflow";
import "swiper/css";

const Testimonial = () => {
  const [isOver, setIsOver] = useState(false);

  const container_style = {
    cursor: isOver ? "grab" : "auto",
  };

  return (
    <section id="testimonial">
      <h1>Testimonials </h1>
      <span>Review from clients</span>
      <div
        className="testimonial-container"
        onMouseOver={() => {
          setIsOver(true);
        }}
        style={container_style}
      >
        <Swiper
          modules={[EffectCoverflow]}
          effect="coverflow"
          spaceBetween={90}
          slidesPerView={3}
        >
          <SwiperSlide>
            <div className="testimonial-post">
              <div className="testimonial-post-info-container">
                <img src="./images/josue.webp" alt="img" />
                <div className="testimonial-post-info">
                  <div className="testimonial-post-name">JOSUE Julian</div>
                  <div className="testimonial-post-job">Web Developper</div>
                  <div className="testimonial-post-evaluation">
                    <Star value={4} />
                  </div>
                  <div className="testimonial-post-message">
                    SITIO worked in our company as an intern and during this
                    period he always carried out his tasks on time and
                    respecting all objectives.
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-post">
              <div className="testimonial-post-info-container">
                <img src="./images/rodrigue.jpg" alt="img" />
                <div className="testimonial-post-info">
                  <div className="testimonial-post-name">GOMSEU Rodrigue</div>
                  <div className="testimonial-post-job">PDG of CAMENCORP</div>
                  <div className="testimonial-post-evaluation">
                    <Star value={5} />
                  </div>
                  <div className="testimonial-post-message">
                    He contributed to the setting up of our site and I can
                    easily say that I am fully satisfied. I highly recommend it
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-post">
              <div className="testimonial-post-info-container">
                <img src="./images/serge.webp" alt="img" />
                <div className="testimonial-post-info">
                  <div className="testimonial-post-name">INACK III Serge</div>
                  <div className="testimonial-post-job">
                    Fullstack Developper
                  </div>
                  <div className="testimonial-post-evaluation">
                    <Star value={4} />
                  </div>
                  <div className="testimonial-post-message">
                    I had sponsored him and worked with him on certain projects
                    and his style of work impressed me because he is always up
                    to date on these tasks and is strong in proposals
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-post">
              <div className="testimonial-post-info-container">
                <img src="./images/koz.png" alt="img" />
                <div className="testimonial-post-info">
                  <div className="testimonial-post-name">KAZOCK Steve</div>
                  <div className="testimonial-post-job">
                    Fullstack Developper
                  </div>
                  <div className="testimonial-post-evaluation">
                    <Star value={3} />
                  </div>
                  <div className="testimonial-post-message">
                    I personally worked with him on our personal projects and I
                    was amazed by his quick thinking when faced with complex
                    problems.
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-post">
              <div className="testimonial-post-info-container">
                <img src="./images/Tane.jpeg" alt="img" />
                <div className="testimonial-post-info">
                  <div className="testimonial-post-name">
                    TANETERTORE Jordan
                  </div>
                  <div className="testimonial-post-job">
                    Fullstack Developper
                  </div>
                  <div className="testimonial-post-evaluation">
                    <Star value={5} />
                  </div>
                  <div className="testimonial-post-message">
                    He is a hard worker. He doesn't sleep until he finishes his
                    obligations. In addition, he is very friendly and
                    comfortable working in a team. I strongly recommend it.
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="testimonial-post">
              <div className="testimonial-post-info-container">
                <img src="./images/wil.webp" alt="img" />
                <div className="testimonial-post-info">
                  <div className="testimonial-post-name">TSAGUE Wilfried</div>
                  <div className="testimonial-post-job">Web Developper</div>
                  <div className="testimonial-post-evaluation">
                    <Star value={4} />
                  </div>
                  <div className="testimonial-post-message">
                    He provided us with first-class work within the fixed
                    deadlines while respecting all our requirements. I can
                    safely say that he will do a good job
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonial;
