import React from "react";

const Star = (props) => {
  return (
    <>
      {props.value == 3 && (
        <>
          <i className="fa fa-star star-active"></i>
          <i className="fa fa-star star-active"></i>
          <i className="fa fa-star star-active"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
        </>
      )}
      {props.value == 4 && (
        <>
          <i className="fa fa-star star-active"></i>
          <i className="fa fa-star star-active"></i>
          <i className="fa fa-star star-active"></i>
          <i className="fa fa-star star-active"></i>
          <i className="fa fa-star star-inactive"></i>
        </>
      )}
      {props.value == 5 && (
        <>
          <i className="fa fa-star star-active"></i>
          <i className="fa fa-star star-active"></i>
          <i className="fa fa-star star-active"></i>
          <i className="fa fa-star star-active"></i>
          <i className="fa fa-star star-active"></i>
        </>
      )}
    </>
  );
};

export default Star;
