import React from "react";
import Service_post from "./components/Service_post";

const Services = () => {
  const data1 = {
    title: "UI / UX Design",
    text: [
      "Identification of the main challenges of the platform",
      "User studies",
      "Development of a typical profile of the user who will come to visit the platform",
      "Creation of storytelling in accordance with the brand image",
      "Realization of a design study",
      "specification of graphic choices through a model",
      "A/B testing",
    ],
  };
  const data2 = {
    title: "Web Development",
    text: [
      "Static / dynamic website",
      "Web application / Personal portfolio",
      "E-commerce website",
      "Landing page",
      "Web hosting",
      "Maintenance / web mastering",
      "Professional SEO",
      "Visit statistics (Google Analytics)",
      "Content Writing",
    ],
  };
  const data3 = {
    title: "Mobile Developement",
    text: [
      "E-commerce / vitrine application",
      "Game application",
      "Analyze customer needs",
      "Propose the best technical solutions",
      "Develop one or more mobile applications",
      "Perform technical and integration tests",
      "Write technical documentation",
      "Monitor the advancement of mobile technologies",
    ],
  };

  return (
    <section id="services">
      <h1>Services </h1>
      <span>What i offer</span>
      <div className="services-container">
        <Service_post data={data1} />
        <Service_post data={data2} />
        <Service_post data={data3} />
      </div>
    </section>
  );
};

export default Services;
