import React from "react";
import { useState } from "react";

const Portfolio_post = (props) => {
  const [isArrow, setIsArrow] = useState(false);
  const [isGit, setIsGit] = useState(false);
  const [isOver, setisOver] = useState(false);

  const a1 = {
    backgroundColor: "#0034e1",
  };

  const a2 = {
    backgroundColor: "#0099c4",
  };

  const imgStyle = {
    transform: isOver ? "scale(1.1)" : "none",
  };

  return (
    <div className="portfolio-post">
      <div className="portfolio-post-img">
        <img
          src={props.data.imageUrl}
          alt="img"
          style={imgStyle}
          onMouseOver={() => {
            setisOver(true);
          }}
          onMouseOut={() => {
            setisOver(false);
          }}
        />
      </div>
      <div className="portfolio-post-info">
        <h3>{props.data.title}</h3>
        <p>{props.data.description}</p>
        <div className="portfolio-post-buttons">
          <a
            className="btn"
            href={props.data.githubUrl}
            onMouseOver={() => {
              setIsGit(true);
            }}
            onMouseOut={() => {
              setIsGit(false);
            }}
            style={a1}
            target="_blank"
            rel="noreferrer"
          >
            Github &nbsp;&nbsp;
            <i
              className={
                isGit
                  ? "fa-brands fa-github show-arrow fa-git"
                  : "fa-brands fa-github fa-git"
              }
            ></i>
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a
            className="btn"
            target="_blank"
            rel="noreferrer"
            href={props.data.demoUrl}
            onMouseOver={() => {
              setIsArrow(true);
            }}
            onMouseOut={() => {
              setIsArrow(false);
            }}
            style={a2}
          >
            Live demo &nbsp;&nbsp;
            <i
              className={
                isArrow
                  ? "fa fa-chevron-right show-arrow"
                  : "fa fa-chevron-right"
              }
            ></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Portfolio_post;
