import React from "react";
import { useState } from "react";

const Contact_info = (props) => {
  const [isOver, setIsOver] = useState(false);

  const title_style = {
    color: isOver ? "#20232A" : "white",
    fontWeight: isOver ? "600" : "normal",
  };

  const value_style = {
    color: !isOver ? "rgb(170, 170, 170)" : "black",
    fontSize: "0.9rem",
  };

  return (
    <div
      className="contact-info-post"
      onMouseOver={() => {
        setIsOver(true);
      }}
      onMouseOut={() => {
        setIsOver(false);
      }}
    >
      <i className={props.icon}></i>
      <div className="contact-info">
        <div className="contact-info-title" style={title_style}>
          {props.title}
        </div>
        <div style={value_style}>{props.value}</div>
      </div>
    </div>
  );
};

export default Contact_info;
