import React from "react";

const Footer = () => {
  const title = "< ST.code />";
  return (
    <footer>
      <img src="./images/logo.png" alt="img" />
      <h2>{title}</h2>
      <span>Copyrigth © 2022 Powered By ST.code All Rights reserved</span>
    </footer>
  );
};

export default Footer;
