import React, { useState } from "react";
import Navbar from "./Navbar";
import Lien from "./components/Lien";
import BarreProgress from "./components/BarreProgress";
import Home from "./Home";
import About from "./About";
import Skills from "./Skills";
import Services from "./Services";
import Portfolio from "./Portfolio";
import Qualifications from "./Qualifications";
import Testimonial from "./Testimonial";
import Contact from "./Contact";
import Footer from "./Footer";
import "./fontawesome-free-6.3.0-web/css/all.min.css";
import "./style.css";

const App = () => {
  const [scrollY, setScrollY] = useState(window.scrollY);

  window.addEventListener("scroll", () => {
    setScrollY(window.scrollY);
  });

  return (
    <>
      <BarreProgress scrollY={scrollY} />
      <Navbar scrollY={scrollY} />
      <Lien />
      <Home />
      <About />
      <Skills />
      <Services />
      <Portfolio />
      <Qualifications />
      <Testimonial />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
